import {
  AuthResult,
  IHandlers,
  IIonicAuth,
  IonicAuthConfig,
  IonicAuthOptions,
  TokenStorageProvider,
  IVUserInterface,
  IV5UserInterface,
} from './interfaces';
import {
  AuthIdentityVault5Storage,
  AuthIdentityVaultStorage,
  AuthLocalStorage,
  isIV5UserInterface,
  isTokenStorageProvider,
} from './storage';
import { SessionHelper } from './session-helper';
import { IonicAuth0Config } from './provider_configs/ionic-auth0-config';
import { IonicAzureConfig } from './provider_configs/ionic-azure-config';
import { IonicCognitoConfig } from './provider_configs/ionic-cognito-config';
import { IonicGeneralAuthConfig } from './provider_configs/ionic-general-auth-config';
import { getRandomNonce, parseJwt } from './crypto-helper';
import { logging, Logger } from './logging';
import { IonicSalesForceAuthConfig } from './provider_configs/ionic-salesforce-config';
import { IonicPingAuthConfig } from './provider_configs/ionic-ping-config';
import { IonicOneLoginConfig } from './provider_configs/ionic-onelogin-config';
import { IonicOktaAuthConfig } from './provider_configs/ionic-okta-config';
import { IdentityServerAuthConfig } from './provider_configs/ionic-identity-server-config';
import { IonicKeyCloakConfig } from './provider_configs/ionic-keycloak-config';
import { NativeStorageProvider, WebStorageProvider } from './StorageProvider';

export abstract class IonicBaseAuth<IDToken extends {} = any>
  implements Partial<IIonicAuth<IDToken>>
{
  protected storage: TokenStorageProvider;
  protected session: SessionHelper;
  protected authResult?: AuthResult;
  protected authConfig: IonicAuthConfig;
  protected logger: Logger;
  protected logTag: string = 'IonicBaseAuth: ';
  protected addedLoginParameters: { [id: string]: string } = {};

  constructor(public options: IonicAuthOptions, protected handlers: IHandlers) {
    this.storage = this.getStorageProvider(options.tokenStorageProvider);
    this.session = new SessionHelper(
      options.clientID,
      this.options.platform === 'web' ? new WebStorageProvider() : new NativeStorageProvider(),
    );
    logging.setLogLevel(options.logLevel);
    this.logger = logging;
    switch (this.options.authConfig) {
      case 'auth0':
        this.authConfig = new IonicAuth0Config(options);
        break;
      case 'azure':
        this.authConfig = new IonicAzureConfig(options);
        break;
      case 'cognito':
        this.authConfig = new IonicCognitoConfig(options);
        break;
      case 'general':
        this.authConfig = new IonicGeneralAuthConfig(options);
        break;
      case 'salesforce':
        this.authConfig = new IonicSalesForceAuthConfig(options);
        break;
      case 'ping':
        this.authConfig = new IonicPingAuthConfig(options);
        break;
      case 'identity-server':
        this.authConfig = new IdentityServerAuthConfig(options, async () => this.getRawIdToken());
        break;
      case 'okta':
        this.authConfig = new IonicOktaAuthConfig(options, async () => this.getRawIdToken());
        break;
      case 'keycloak':
        this.authConfig = new IonicKeyCloakConfig(options);
        break;
      case 'onelogin':
        this.authConfig = new IonicOneLoginConfig(options, async () => this.getRawIdToken());
        break;
      default:
        this.authConfig = new IonicAzureConfig(options);
        break;
    }
  }

  public async getRawIdToken() {
    let idToken: string | undefined = undefined;
    if (this.storage.getIdToken) {
      idToken = await this.storage.getIdToken();
    } else {
      idToken = this.authResult && this.authResult.idToken;
    }
    return idToken;
  }

  public setStorageOnLockCallback(onLockCallback: () => void) {
    if (this.storage.onLock && typeof this.storage.onLock === 'function') {
      this.storage.onLock(onLockCallback);
    }
  }

  protected abstract internalHandleCallback(
    url: string,
    externalCallback: boolean,
  ): Promise<AuthResult>;

  protected abstract showUrl(
    url: string,
    options?: { iosWebView?: 'private' | 'shared'; hide?: boolean },
    urlToCloseWindow?: string,
  ): Promise<{ event: string; callback: string } | void>;

  abstract refreshSession(tokenName?: string): Promise<void>;

  private getStorageProvider(
    type?: 'localStorage' | TokenStorageProvider | IVUserInterface | IV5UserInterface,
  ): TokenStorageProvider {
    if (!type || type === 'localStorage') {
      const authLocalStorage = new AuthLocalStorage();
      authLocalStorage.setClientId(this.options.clientID);
      return authLocalStorage;
    } else if (isTokenStorageProvider(type)) {
      return type;
    } else if (isIV5UserInterface(type)) {
      const authIV5Storage = new AuthIdentityVault5Storage(type);
      authIV5Storage.setClientId(this.options.clientID);
      return authIV5Storage;
    } else {
      const authIVStorage = new AuthIdentityVaultStorage(type);
      authIVStorage.setClientId(this.options.clientID);
      return authIVStorage;
    }
  }

  private async validateIdToken(idToken: string): Promise<any> {
    return parseJwt(idToken).payload;
  }

  protected async setSession(authResult: AuthResult, tokenName?: string, scopes?: string) {
    const expiresAt = authResult.expiresIn
      ? authResult.expiresIn * 1000 + new Date().getTime()
      : undefined;

    if (expiresAt) {
      this.logger.debug('setting expires at', expiresAt);
      await this.session.setExpiresAt(expiresAt, tokenName);
    } else {
      this.logger.debug('no expiration sent in result');
    }

    // if we have a valid tokenName we are refreshing a secondary token
    //   save the scopes, but not the result
    if (tokenName) {
      await this.session.setTokenScopes(scopes as string, tokenName as string);
    } else {
      this.authResult = authResult;
    }

    if (this.storage.setIdToken && authResult.idToken) {
      await this.storage.setIdToken(authResult.idToken);
    }
    if (this.storage.setAccessToken && authResult.accessToken) {
      await this.storage.setAccessToken(authResult.accessToken, tokenName);
    }
    if (this.storage.setRefreshToken && authResult.refreshToken) {
      await this.storage.setRefreshToken(authResult.refreshToken);
    }
    return this.authResult;
  }

  additionalLoginParameters(parameters: { [id: string]: string }): void {
    this.addedLoginParameters = parameters;
  }

  async setOverrideDiscoveryUrl(url: string) {
    this.authConfig.overrideDiscoveryUrl = url;
    await this.session.setOverrideUrl(url);
  }

  async clearOverrideDiscoveryUrl() {
    this.authConfig.overrideDiscoveryUrl = '';
    await this.session.clearOverrideUrl();
  }

  async getOverrideDiscoveryUrl() {
    this.authConfig.overrideDiscoveryUrl = (await this.session.getOverrideUrl()) || '';
    return this.authConfig.overrideDiscoveryUrl || undefined;
  }

  async getAccessTokenExpiration(tokenName?: string) {
    const expiresAt = await this.session.getExpiresAt(tokenName);
    return typeof expiresAt === 'number' ? expiresAt : undefined;
  }

  async login(overrideUrl?: string): Promise<void> {
    return new Promise(async (resolve, reject) => {
      const keys = await this.authConfig.generateChallengeAndVerifier();
      const nonce = getRandomNonce();
      await this.session.clearAuthData();
      await this.session.setAuthData(keys);
      await this.session.setNonce(nonce);
      const previousOverrideUrl = await this.session.getOverrideUrl();
      this.authConfig.overrideDiscoveryUrl = overrideUrl || previousOverrideUrl || '';
      try {
        const url = await this.authConfig.getAuthorizeUrl(
          nonce,
          keys.challenge,
          this.addedLoginParameters,
        );

        this.showUrl(url.url!, undefined, this.options.redirectUri!)
          .then(async (result: any) => {
            const callbackString: string = result.callback;

            if (callbackString) {
              const searchParams = new URL(callbackString).searchParams;
              if (searchParams.has('error_description')) {
                const errorDescription: string = searchParams.get('error_description') as string;
                logging.debug('error_description' + errorDescription);
                throw new Error(errorDescription);
              }
              const authResult = await this.internalHandleCallback(callbackString, false);
              this.onLoginSuccess(authResult);
              resolve();
            } else {
              if (result.event === 'closed') {
                throw new Error('browser was closed');
              } else {
                throw new Error('no callback string');
              }
            }
          })
          .catch(error => {
            reject(error);
          });
      } catch (err) {
        reject(err);
      }
    });
  }

  async getIdToken() {
    const idToken = await this.getRawIdToken();
    if (!idToken) {
      return;
    }
    const result = await this.validateIdToken(idToken);
    return result;
  }

  async getAuthResponse() {
    if (this.storage.getAuthResponse) {
      return this.storage.getAuthResponse();
    }
  }

  async handleLoginCallback(url: string = window.location.href) {
    const authResult = await this.internalHandleCallback(url, true);
    this.onLoginSuccess(authResult);
    return authResult;
  }

  async handleLogoutCallback() {
    return this.finishLogout();
  }

  /**
   * @deprecated Use `handleLoginCallback()` instead
   */
  async handleCallback(url: string): Promise<AuthResult> {
    return this.handleLoginCallback(url);
  }

  async isAccessTokenAvailable(tokenName?: string): Promise<boolean> {
    if (this.storage.getAccessToken) {
      const token = await this.storage.getAccessToken(tokenName);
      return !!token;
    }

    return false;
  }

  async isAccessTokenExpired(tokenName?: string): Promise<boolean> {
    const expiresAt = await this.session.getExpiresAt(tokenName);
    this.logger.debug(this.logTag, 'expiresAt: ', expiresAt);

    // If the result didn't include an expires_in we can't know whether it's expired or not
    return typeof expiresAt === 'number' ? new Date().getTime() >= expiresAt : false;
  }

  async isAuthenticated(tokenName?: string): Promise<boolean> {
    const idToken = await this.getIdToken();
    if (!idToken) {
      this.logger.debug(this.logTag, 'no idToken, false');
      return false;
    }
    try {
      let isAuthenticated = !(await this.isAccessTokenExpired(tokenName));
      if (!isAuthenticated) {
        this.logger.debug(this.logTag, 'after expiresAt time');
        try {
          await this.refreshSession(tokenName);
          this.logger.debug(this.logTag, 'refresh succeeded, returning true');
          isAuthenticated = true;
        } catch (e) {
          await this.clearStorage();
          this.logger.debug(this.logTag, 'refresh threw, false', e);
          isAuthenticated = false;
        }
      }
      return isAuthenticated;
    } catch (e) {
      this.logger.error(`${this.logTag} isAuthenticated`, e);
      await this.clearStorage();
      return false;
    }
  }

  async getRefreshToken(): Promise<string | undefined> {
    return this.storage.getRefreshToken ? await this.storage.getRefreshToken() : undefined;
  }

  async isRefreshTokenAvailable(): Promise<boolean> {
    return !!(await this.getRefreshToken());
  }

  protected async internalGetToken(
    codeName: string,
    code: string,
    grantType: string,
    verifier: any,
    scope: string | undefined,
  ): Promise<AuthResult> {
    throw Error('Not Implemented');
  }

  async getAccessToken(tokenName?: string, scopes?: string) {
    const isAuthenticated = await this.isAuthenticated();
    if (!isAuthenticated) {
      this.logger.debug(this.logTag, 'Not authenticated, refresh failed.');
      throw 'Not authenticated, refresh failed.';
    }

    if (this.storage.getAccessToken) {
      this.logger.debug(this.logTag, 'returning storage accessToken', tokenName);
      if (tokenName) {
        const tempAccessToken = await this.storage.getAccessToken(tokenName);

        if (tempAccessToken) {
          const isAuthenticatedToken = await this.isAuthenticated(tokenName);

          if (!isAuthenticatedToken) {
            this.logger.debug(this.logTag, 'Not authenticated, refresh2 failed.');
            throw 'Not authenticated, refresh2 failed.';
          }
        }
      }

      const accessToken = await this.storage.getAccessToken(tokenName);
      if (accessToken) {
        return accessToken;
      }
    }

    if (tokenName) {
      let session = await this.session.getAuthData();
      if (!session) {
        session = await this.authConfig.generateChallengeAndVerifier();
      }

      let refreshToken: string = '';
      if (this.storage.getRefreshToken) {
        refreshToken = (await this.storage.getRefreshToken()) as string;
      }

      const result = await this.internalGetToken(
        'refresh_token',
        refreshToken,
        'refresh_token',
        session.verifier,
        scopes,
      );
      if (result) {
        await this.setSession(result, tokenName, scopes);
        this.logger.debug(this.logTag, 'Acquired a new token.', tokenName, scopes);
        return result.accessToken;
      }

      this.logger.debug(this.logTag, 'Could not acquire a new token for: ', tokenName, scopes);
      throw 'No token could be acquired.';
    } else {
      if (this.storage.getAuthResponse) {
        const authResponse = await this.storage.getAuthResponse();
        if (authResponse && authResponse.accessToken) {
          this.logger.debug(this.logTag, 'returning authResponse accessToken');
          return authResponse.accessToken;
        }
      }

      if (this.authResult && this.authResult.accessToken) {
        this.logger.debug(this.logTag, 'returning authResult accessToken');
        return this.authResult.accessToken;
      }
    }

    this.logger.debug(this.logTag, 'Could not find a token, failing.');
    throw 'Authenticated, but token not found.';
  }

  async expire(tokenName?: string) {
    await this.session.setExpiresAt(0, tokenName);
  }

  async logout() {
    await this.getOverrideDiscoveryUrl();
    const url = await this.authConfig.getLogoutUrl();
    logging.debug('logout url: ' + url.url);
    await this.showUrl(url.url!, { hide: true }, this.options.logoutUrl);
    await this.finishLogout();
  }

  private async finishLogout() {
    this.authResult = undefined;
    await this.clearStorage();
    this.authConfig.locations = undefined;
    await this.clearOverrideDiscoveryUrl();
    this.onLogout();
  }

  onLoginSuccess(authResponse: any) {
    this.authConfig.locations = undefined;
    this.authConfig.overrideDiscoveryUrl = '';
    this.handlers.onLoginSuccess(authResponse);
  }

  onLogout() {
    this.handlers.onLogout();
  }

  async clearStorage() {
    await this.session.clear();
    if (this.storage.clear) {
      await this.storage.clear();
    }
  }
}
