import { logging } from './logging';
import { StorageProvider } from './StorageProvider';

interface AuthCryptoData {
  challenge: string;
  verifier: string;
}

export class SessionHelper {
  private authDataKey = 'ionicauth.authdata';
  private expiresAtKey = 'ionicauth.expiresAt';
  private nonceKey = 'ionicauth.nonce';
  private tokenScopes = 'ionicauth.scopes';
  private overrideUrlKey = 'ionicauth.overrideUrl';
  private expiresAtKeys: string[] = [];
  private tokenScopesKeys: string[] = [];
  private storage: StorageProvider;

  constructor(clientId: string, storage: StorageProvider) {
    this.authDataKey = clientId + '.' + this.authDataKey;
    this.expiresAtKey = clientId + '.' + this.expiresAtKey;
    this.nonceKey = clientId + '.' + this.nonceKey;
    this.overrideUrlKey = clientId + '.' + this.overrideUrlKey;
    this.tokenScopes = clientId + '.' + this.tokenScopes;
    this.storage = storage;
  }

  async getAuthData() {
    const dataKey = await this.storage.get(this.authDataKey);
    if (dataKey) {
      const session: AuthCryptoData = JSON.parse(dataKey);
      return session;
    } else {
      return undefined;
    }
  }

  async setAuthData(session: AuthCryptoData) {
    await this.storage.set(this.authDataKey, JSON.stringify(session));
  }

  async setOverrideUrl(url: string) {
    await this.storage.set(this.overrideUrlKey, url);
  }

  async getOverrideUrl() {
    const overrideUrl = await this.storage.get(this.overrideUrlKey);
    return overrideUrl ? overrideUrl : undefined;
  }

  async clearOverrideUrl() {
    await this.storage.remove(this.overrideUrlKey);
  }

  async clearAuthData() {
    await this.storage.remove(this.authDataKey);
  }

  async getExpiresAt(tokenName?: string) {
    let expiresAtKeyName = this.expiresAtKey;
    if (tokenName) {
      expiresAtKeyName = this.expiresAtKey + '.' + tokenName;
    }
    const expiresAtKey = await this.storage.get(expiresAtKeyName);
    if (expiresAtKey) {
      const expiresAt: number | undefined = JSON.parse(expiresAtKey);
      return expiresAt;
    }
    return undefined;
  }

  async setExpiresAt(expiresAt: number, tokenName?: string) {
    let expiresAtKey = this.expiresAtKey;
    if (tokenName) {
      expiresAtKey = this.expiresAtKey + '.' + tokenName;
      this.tokenScopesKeys.push(expiresAtKey);
    }
    logging.debug('setExpiresAt', 'expiresAtKey', expiresAtKey);
    await this.storage.set(expiresAtKey, JSON.stringify(expiresAt));
  }

  async getTokenScopes(tokenName: string) {
    let tokenScopesKey = this.tokenScopes + '.' + tokenName;
    logging.debug('getTokenScopes', 'tokenScopes', tokenScopesKey);
    const tokenScopes = await this.storage.get(tokenScopesKey);
    if (tokenScopes) {
      const expiresAt: string | undefined = JSON.parse(tokenScopes);
      return expiresAt;
    }
    return undefined;
  }

  async setTokenScopes(scopes: string, tokenName: string) {
    let tokenScopesKey = this.tokenScopes + '.' + tokenName;
    this.tokenScopesKeys.push(tokenScopesKey);
    logging.debug('setTokenScopes', 'tokenScopes', tokenScopesKey);
    await this.storage.set(tokenScopesKey, JSON.stringify(scopes));
  }

  async clearTokenScopes() {
    this.tokenScopesKeys.forEach(async key => {
      await this.storage.remove(key);
    });
  }

  async clearExpiresAt() {
    await this.storage.remove(this.expiresAtKey);
    this.expiresAtKeys.forEach(async key => {
      await this.storage.remove(key);
    });
  }

  async getNonce() {
    const nonceKey = await this.storage.get(this.nonceKey);
    if (nonceKey) {
      const nonce: string | undefined = JSON.parse(nonceKey);
      return nonce;
    }
    return undefined;
  }

  async setNonce(nonce: string) {
    await this.storage.set(this.nonceKey, JSON.stringify(nonce));
  }

  async clearNonce() {
    await this.storage.remove(this.nonceKey);
  }

  async clear() {
    await this.clearAuthData();
    await this.clearExpiresAt();
    await this.clearNonce();
    await this.clearTokenScopes();
  }
}
