import { AuthResult, IIonicAuth, IonicAuthOptions } from './interfaces';
import { IonicAuthWeb } from './ionic-auth-web';
import { IonicNativeAuth } from './ionic-auth-cordova';

export class IonicAuth<IDToken extends {} = any> implements IIonicAuth<IDToken> {
  private implementation: IIonicAuth;

  constructor(options: IonicAuthOptions) {
    this.implementation = this.getImplementation(options);
  }

  /**
   * Using configuration display the auth provider's login UI.
   *
   *  The overrideUrl parameter should only be used when the default
   *  discovery url needs to be overrode. (The known use case is with Azure AD
   *  custom user flows/policies.)
   *
   * @example
   * myAuthService.login("")
   */
  login(overrideUrl?: string) {
    return this.implementation.login(overrideUrl);
  }

  /**
   * Add additional parameters to the login request.
   *
   * @example
   * myAuthService.additionalLoginParameters({ 'login_hint': 'neighbors cat name' })
   *
   * @param parameters any additional parameters that should be added to the login request
   *  examples: `login_hint`, `domain_hint`
   */
  additionalLoginParameters(parameters: { [id: string]: string }) {
    return this.implementation.additionalLoginParameters(parameters);
  }

  /**
   * Get the access token, once logged in, for API calls.
   *
   * @example
   * myAuthService.getAccessToken()
   *
   * @param tokenName Optional token name, only used when multiple tokens are required (Azure specific feature).
   * @param scopes The scopes for the access token.
   */
  getAccessToken(tokenName?: string, scopes?: string) {
    return this.implementation.getAccessToken(tokenName, scopes);
  }

  /**
   * Get the unparsed id token.
   *
   * @example
   * myAuthService.getRawIdToken()
   */
  getRawIdToken(): Promise<string | undefined> {
    return this.implementation.getRawIdToken();
  }

  /**
   * Get the parsed id token, includes requested scope values.
   *
   * @example
   * myAuthService.getIdToken()
   */
  getIdToken(): Promise<IDToken | undefined> {
    return this.implementation.getIdToken();
  }

  /**
   * Get the full original auth response.
   *
   * @example
   * myAuthService.getAuthResponse()
   */
  getAuthResponse() {
    return this.implementation.getAuthResponse();
  }

  /**
   * Check to see if the access token is available.
   *
   * @example
   * myAuthService.isAccessTokenAvailable()
   *
   * @param tokenName Optional token name, only used when multiple tokens are required (Azure specific feature).
   */
  isAccessTokenAvailable(tokenName?: string) {
    return this.implementation.isAccessTokenAvailable(tokenName);
  }

  /**
   * Check to see if the access token is expired.
   *
   * @example
   * myAuthService.isAccessTokenExpired()
   */
  isAccessTokenExpired() {
    return this.implementation.isAccessTokenExpired();
  }

  /**
   * Check to see if the refresh token is available.
   *
   * @example
   * myAuthService.isRefreshTokenAvailable()
   */
  isRefreshTokenAvailable() {
    return this.implementation.isRefreshTokenAvailable();
  }

  /**
   * Get the refresh token if available.
   *
   * @example
   * myAuthService.getRefreshToken()
   */
  getRefreshToken() {
    return this.implementation.getRefreshToken();
  }

  /**
   * Refresh the session, throws if refresh token is invalid or missing.
   *
   * @example
   * myAuthService.refreshSession()
   *
   * @param tokenName Optional token name, only used when multiple tokens are required (Azure specific feature).
   */
  refreshSession() {
    return this.implementation.refreshSession();
  }

  /**
   * Check to see if the user is logged in, and refresh the token if needed.
   *
   * @example
   * const isAuth = myAuthService.isAuthenticated()
   */
  isAuthenticated() {
    return this.implementation.isAuthenticated();
  }

  /**
   * Log the user out and clear all tokens & data stored in the {@link TokenStorageProvider} as well as any
   * metadata relevant to the existing session such as access token expiration time.
   *
   * @example
   * myAuthService.logout()
   */
  logout() {
    return this.implementation.logout();
  }

  /**
   * Expire the current access token, but keep the refresh token, useful for testing.
   *
   * @example
   * myAuthService.expire()
   */
  expire() {
    return this.implementation.expire();
  }

  /**
   * Called by the hosting app when login callbacks happen, these will be to the URL specified
   *  in the options for RedirectUri.
   *
   * @example
   * myAuthService.handleLoginCallback()
   *
   * @param url callback url to handle @default defaults to `window.location.href`
   */
  handleLoginCallback(url?: string) {
    return this.implementation.handleLoginCallback(url);
  }

  /**
   * Called by the hosting app when logout callbacks happens.
   *
   * @example
   * myAuthService.handleLogoutCallback()
   */
  handleLogoutCallback() {
    return this.implementation.handleLogoutCallback();
  }

  /**
   * Called by the hosting app when callbacks happen, these will be to the URL specified
   *  in the options for LogoutUrl and RedirectUri.
   *
   * @example
   * myAuthService.handleCallback(window.location.href)
   *
   * @deprecated Use [handleLoginCallback](#iionicauth.handlelogincallback) instead
   * @param url callback url to handle
   */
  handleCallback(url: string) {
    return this.implementation.handleLoginCallback(url);
  }

  /**
   * This method will clear all tokens & data stored in the {@link TokenStorageProvider} as well as any
   * metadata relevant to the existing session such as access token expiration time.
   *
   * @example
   * myAuthService.clearStorage()
   */
  async clearStorage() {
    return this.implementation.clearStorage();
  }

  /**
   * Override the discovery url used for login in a way that persists.
   *
   * @example
   * myAuthService.setOverrideDiscoveryUrl("https://myurl")
   *
   * @param url the discovery url used for login
   */
  setOverrideDiscoveryUrl(url: string) {
    return this.implementation.setOverrideDiscoveryUrl(url);
  }

  /**
   * Clear previosly persisted override of the discovery url used for login.
   *
   * @example
   * myAuthService.clearOverrideDiscoveryUrl()
   */
  clearOverrideDiscoveryUrl() {
    return this.implementation.clearOverrideDiscoveryUrl();
  }

  /**
   * Clear previosly persisted override of the discovery url used for login.
   *
   * @example
   * myAuthService.getOverrideDiscoveryUrl()
   */
  getOverrideDiscoveryUrl() {
    return this.implementation.getOverrideDiscoveryUrl();
  }

  /**
   * Get the time the access token will expire in milliseconds from the epoch.
   *
   * @example
   * myAuthService.getAccessTokenExpiration()
   */
  getAccessTokenExpiration() {
    return this.implementation.getAccessTokenExpiration();
  }

  /**
   * Event handler which can be overridden to handle successful login events.
   *
   * @usage
   * ```typescript
   * async onLoginSuccess(): Promise<void> {
   *  // do something here
   * }
   * ```
   * @param result the auth result from a successful login
   */
  onLoginSuccess(result: AuthResult): void {}

  /**
   * Event handler which can be overridden to handle successful logout events.
   *
   * @usage
   * ```typescript
   * async onLogout(): Promise<void> {
   *  // do something here
   * }
   * ```
   */
  onLogout(): void {}

  getImplementation(options: IonicAuthOptions) {
    switch (options.platform) {
      case 'web':
        return new IonicAuthWeb<IDToken>(options, {
          onLoginSuccess: this.onLoginSuccess.bind(this),
          onLogout: this.onLogout.bind(this),
        });
      default:
        return new IonicNativeAuth<IDToken>(options, {
          onLoginSuccess: this.onLoginSuccess.bind(this),
          onLogout: this.onLogout.bind(this),
        });
    }
  }
}
