import { AuthResult, IIonicAuth } from './interfaces';
import { UrlInfo } from './UrlInfo';
import { IonicSecureWebView } from './SecureWebView';
import parse from 'url-parse';
import { IonicBaseAuth } from './base-auth';
import { UrlHelper } from './urlHelper';

const ready = new Promise<void>(resolve => {
  const DEVICE_READY_TIMEOUT = 5000;
  const readyTimeout = setTimeout(() => {
    console.warn(`Auth Connect: deviceready did not fire within ${DEVICE_READY_TIMEOUT}ms.`);
    resolve();
  }, DEVICE_READY_TIMEOUT);

  document.addEventListener('deviceready', () => {
    clearTimeout(readyTimeout);
    resolve();
  });
});

export class IonicNativeAuth<IdToken extends {} = any>
  extends IonicBaseAuth<IdToken>
  implements IIonicAuth<IdToken>
{
  protected logTag: string = 'IonicNativeAuth: ';

  protected async internalGetToken(
    codeName: string,
    code: string,
    grantType: string,
    verifier: any,
    scope: string | undefined,
  ): Promise<AuthResult> {
    let payload: any;

    payload = {
      grant_type: grantType,
      client_id: this.options.clientID,
      code_verifier: verifier,
      redirect_uri: String(this.options.redirectUri),
    };
    payload[codeName] = String(code);

    const tokenUrlInfo: UrlInfo = await this.authConfig.getTokenUrl();
    const tokenUrl: string = tokenUrlInfo.url || '';
    if (tokenUrlInfo.payload) {
      payload = { ...tokenUrlInfo.payload, ...payload };
    }

    if (scope != undefined) {
      payload.scope = scope;
    }

    let headers = {};
    if (tokenUrlInfo.headers) {
      headers = tokenUrlInfo.headers;
    } else {
      headers = {
        'Content-Type': 'application/json',
      };
    }
    // need to await device ready

    return await UrlHelper.post(tokenUrl, payload, headers)
      .then(async response => {
        const result = JSON.parse(response.data);
        if (this.storage.setAuthResponse && grantType != 'refresh_token') {
          await this.storage.setAuthResponse(result);
        }
        this.logger.debug(this.logTag, 'got result', result);
        this.logger.debug(this.logTag, 'access_token', result.access_token);
        this.logger.debug(this.logTag, 'id_token', result.id_token);
        this.logger.debug(this.logTag, 'refresh_token', result.refresh_token);
        this.logger.debug(this.logTag, 'profile_info', result.profile_info);
        this.logger.debug(this.logTag, 'expires_in', result.expires_in);
        const authResult: AuthResult = {
          accessToken: result.access_token,
          idToken: result.id_token,
          refreshToken: result.refresh_token,
          expiresIn: result.expires_in,
          scope: result.scope,
          tokenType: result.token_type,
        };
        return authResult;
      })
      .catch(error => {
        this.logger.debug(tokenUrl, this.logTag, ' - tokenUrl');
        this.logger.debug(headers, this.logTag, ' - headers');
        this.logger.debug(payload, this.logTag, ' - payload');
        this.logger.error(this.logTag, error);
        return {};
      });
  }

  async internalHandleCallback(url: string, _externalCallback: boolean): Promise<AuthResult> {
    const session = await this.session.getAuthData();
    if (!session) {
      throw new Error('No session data stored');
    }
    let { code } = parse(url, true).query;
    await ready;
    let grantType = 'authorization_code';
    let codeName = 'code';

    try {
      const result = await this.internalGetToken(
        codeName,
        code as string,
        grantType,
        session.verifier,
        undefined,
      );

      await this.setSession(result);
      await this.session.clearAuthData();

      IonicSecureWebView.hide(
        data => {
          this.logger.debug(this.logTag, 'IonicSecureWebView.hide succeeded: ', data);
        },
        err => {
          this.logger.error(this.logTag, 'IonicSecureWebView.hide failed: ', err);
        },
      );
      return result;
    } catch (err) {
      await this.session.clearAuthData();
      this.logout();
      throw err;
    }
  }

  async refreshSession(tokenName?: string): Promise<void> {
    await this.getOverrideDiscoveryUrl();
    this.logger.debug(this.logTag, 'refresh flow');
    const url = await this.authConfig.getTokenUrl();
    const nonce = await this.session.getNonce();
    const refreshToken = await this.getRefreshToken();
    if (!refreshToken) {
      throw new Error('No refresh token available');
    }
    let payload: any;
    payload = {
      client_id: this.options.clientID,
      refresh_token: refreshToken,
      grant_type: 'refresh_token',
      nonce,
      state: nonce,
    };

    if (url.payload) {
      payload = { ...url.payload, ...payload };
    }
    let secondaryToken = false;
    if (tokenName) {
      payload.scope = await this.session.getTokenScopes(tokenName);
      secondaryToken = true;
    }

    const headers = url.headers ? url.headers : { 'Content-Type': 'application/json' };

    await ready;
    return await UrlHelper.post(url.url || '', payload, headers)
      .then(async response => {
        const result = JSON.parse(response.data);
        if (this.storage.setAuthResponse && !secondaryToken) {
          await this.storage.setAuthResponse(result);
        }

        const authResult = {
          accessToken: result.access_token,
          idToken: result.id_token,
          refreshToken: result.refresh_token,
          expiresIn: result.expires_in,
          scope: result.scope,
          tokenType: result.token_type,
        };
        await this.setSession(authResult, tokenName, result.scope);
        return;
      })
      .catch(error => {
        this.logger.debug(this.logTag, 'tokenUrl: ' + url.url);
        this.logger.debug(this.logTag, 'headers: ', headers);
        this.logger.debug(this.logTag, 'payload: ', payload);
        this.logger.error(this.logTag, error);
        throw error;
      });
  }

  protected showUrl(url: string, options?: { hide?: boolean }): Promise<any> {
    return new Promise((resolve, reject) => {
      let curOptions: { [key: string]: any } = {};
      if (this.options.androidToolbarColor) {
        curOptions = {
          ...curOptions,
          toolbarColor: this.options.androidToolbarColor,
        };
      }
      if (this.options.safariWebViewOptions) {
        curOptions = { ...curOptions, ...this.options.safariWebViewOptions };
      }

      this.logger.debug(this.logTag, 'webView option: ', this.authConfig.options.iosWebView);
      const params = Object.assign(
        {},
        {
          url,
          callbackUrl: this.authConfig.options.redirectUri,
          iosWebView: this.authConfig.options.iosWebView,
        },
        curOptions,
      );
      this.logger.debug(this.logTag, 'using params: ', params);
      IonicSecureWebView.show(
        params,
        (result: any) => {
          this.logger.debug(this.logTag, 'result :', result);
          resolve(result);
        },
        (error: any) => {
          this.logger.error(this.logTag, 'show failed: ', error);
          reject(error);
        },
      );
    });
  }
}
