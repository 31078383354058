import {
  TokenStorageProvider,
  IVUserInterface,
  VaultInterface,
  IV5UserInterface,
} from './interfaces';
import { logging } from './logging';

abstract class BaseStorage {
  protected accessTokenKey = '_ionicAuth.accessToken';
  protected refreshTokenKey = '_ionicAuth.refreshToken';
  protected idTokenKey = '_ionicAuth.idToken';
  protected authResponseKey = '_ionicAuth.authResponse';
  setClientId(clientId: string) {
    this.accessTokenKey = this.accessTokenKey + '.' + clientId;
    this.refreshTokenKey = this.refreshTokenKey + '.' + clientId;
    this.idTokenKey = this.idTokenKey + '.' + clientId;
    this.authResponseKey = this.authResponseKey + '.' + clientId;
  }

  // some keys need to be store per token, helper to ensure consistency
  formatKeyForToken(keyName: string, tokenName?: string): string {
    return tokenName ? this.accessTokenKey + '.' + tokenName : this.accessTokenKey;
  }
}

export const isTokenStorageProvider = (obj: any): obj is TokenStorageProvider => {
  const provider = obj as TokenStorageProvider;
  return (
    (typeof provider === 'object' &&
      provider.getAccessToken &&
      typeof provider.getAccessToken === 'function') ||
    (provider.getAuthResponse && typeof provider.getAuthResponse === 'function') ||
    (provider.getIdToken && typeof provider.getIdToken === 'function') ||
    (provider.getRefreshToken && typeof provider.getRefreshToken === 'function') ||
    (provider.setAccessToken && typeof provider.setAccessToken === 'function') ||
    (provider.setAuthResponse && typeof provider.setAuthResponse === 'function') ||
    (provider.setIdToken && typeof provider.setIdToken === 'function') ||
    (provider.setRefreshToken && typeof provider.setRefreshToken === 'function') ||
    false
  );
};

export const isIV5UserInterface = (obj: any): obj is IV5UserInterface => {
  const provider = obj as IV5UserInterface;
  return (
    provider.getValue &&
    typeof provider.getValue === 'function' &&
    provider.setValue &&
    typeof provider.setValue === 'function' &&
    provider.clear &&
    typeof provider.clear === 'function' &&
    provider.onLock &&
    typeof provider.onLock === 'function'
  );
};

export class AuthIdentityVault5Storage extends BaseStorage implements TokenStorageProvider {
  constructor(private vault: IV5UserInterface) {
    super();
  }

  /**
   * get the saved access token
   */
  async getAccessToken(tokenName?: string): Promise<string | undefined> {
    const key = this.formatKeyForToken(this.accessTokenKey, tokenName);
    logging.debug('getAccessToken key:', key);
    return (await this.vault.getValue<string>(key)) ?? undefined;
  }

  /**
   * save the access token
   */
  async setAccessToken(accessToken: string | undefined, tokenName?: string) {
    const key = this.formatKeyForToken(this.accessTokenKey, tokenName);
    logging.debug('setAccessToken: key: ', key);
    return this.vault.setValue(key, accessToken);
  }

  /**
   * get the saved refresh token
   */
  async getRefreshToken(): Promise<string | undefined> {
    return (await this.vault.getValue(this.refreshTokenKey)) ?? undefined;
  }

  /**
   * save the refresh token
   */
  async setRefreshToken(refreshToken: string) {
    return this.vault.setValue(this.refreshTokenKey, refreshToken);
  }

  /**
   * get the id token
   */
  async getIdToken(): Promise<string | undefined> {
    return (await this.vault.getValue(this.idTokenKey)) ?? undefined;
  }

  /**
   * save the id token
   */
  async setIdToken(idToken: string) {
    return this.vault.setValue(this.idTokenKey, idToken);
  }

  /**
   * get the full auth result
   */
  async getAuthResponse() {
    return this.vault.getValue(this.authResponseKey);
  }

  /**
   * save the full auth response
   */
  async setAuthResponse(response: any) {
    return this.vault.setValue(this.authResponseKey, response);
  }

  async clear() {
    return this.vault.clear();
  }

  onLock(callback: () => void) {
    this.vault.onLock(callback);
  }
}

export class AuthIdentityVaultStorage extends BaseStorage implements TokenStorageProvider {
  constructor(private iv: IVUserInterface) {
    super();
  }

  private async ensureVaultConfigured(setPasscodeIfNeeded: boolean): Promise<VaultInterface> {
    const vault = await this.iv.getVault();
    const ivConfig = await vault.getConfig();
    if (ivConfig.isPasscodeSetupNeeded && setPasscodeIfNeeded) {
      await this.iv.setPasscode();
    }
    return vault;
  }

  /**
   * get the saved access token
   */
  async getAccessToken(tokenName?: string): Promise<string | undefined> {
    const key = this.formatKeyForToken(this.accessTokenKey, tokenName);
    logging.debug('getAccessToken: key: ', key);
    const vault = await this.ensureVaultConfigured(false);
    return vault.getValue(key);
  }

  /**
   * save the access token
   */
  async setAccessToken(accessToken: string | undefined, tokenName?: string) {
    const key = this.formatKeyForToken(this.accessTokenKey, tokenName);
    logging.debug('setAccessToken: key: ', key);
    const vault = await this.ensureVaultConfigured(true);
    return vault.storeValue(key, accessToken);
  }

  /**
   * get the saved refresh token
   */
  async getRefreshToken(): Promise<string | undefined> {
    const vault = await this.ensureVaultConfigured(false);
    return vault.getValue(this.refreshTokenKey);
  }

  /**
   * save the refresh token
   */
  async setRefreshToken(refreshToken: string) {
    const vault = await this.ensureVaultConfigured(true);
    return vault.storeValue(this.refreshTokenKey, refreshToken);
  }

  /**
   * get the id token
   */
  async getIdToken(): Promise<string | undefined> {
    const vault = await this.ensureVaultConfigured(false);
    return vault.getValue(this.idTokenKey);
  }

  /**
   * save the id token
   */
  async setIdToken(idToken: string) {
    const vault = await this.ensureVaultConfigured(true);
    return vault.storeValue(this.idTokenKey, idToken);
  }

  /**
   * get the full auth result
   */
  async getAuthResponse() {
    const vault = await this.ensureVaultConfigured(false);
    return vault.getValue(this.authResponseKey);
  }

  /**
   * save the full auth response
   */
  async setAuthResponse(response: any) {
    const vault = await this.ensureVaultConfigured(true);
    return vault.storeValue(this.authResponseKey, response);
  }

  async clear() {
    const vault = await this.iv.getVault();
    return vault.clear();
  }
}

export class AuthLocalStorage extends BaseStorage implements TokenStorageProvider {
  /**
   * get the saved access token
   */
  async getAccessToken(tokenName?: string): Promise<string | undefined> {
    const key = this.formatKeyForToken(this.accessTokenKey, tokenName);
    const accessToken = localStorage.getItem(key);
    return accessToken || undefined;
  }

  /**
   * save the access token
   */
  async setAccessToken(accessToken: string, tokenName?: string) {
    const key = this.formatKeyForToken(this.accessTokenKey, tokenName);
    return localStorage.setItem(key, accessToken);
  }

  /**
   * get the saved refresh token
   */
  async getRefreshToken(): Promise<string | undefined> {
    const refreshToken = localStorage.getItem(this.refreshTokenKey);
    return refreshToken || undefined;
  }

  /**
   * save the refresh token
   */
  async setRefreshToken(refreshToken: string) {
    return localStorage.setItem(this.refreshTokenKey, refreshToken);
  }

  /**
   * get the id token
   */
  async getIdToken(): Promise<string | undefined> {
    const idToken = localStorage.getItem(this.idTokenKey);
    return idToken || undefined;
  }

  /**
   * save the id token
   */
  async setIdToken(idToken: string) {
    return localStorage.setItem(this.idTokenKey, idToken);
  }

  /**
   * get the full auth result
   */
  async getAuthResponse() {
    const authResponseString = localStorage.getItem(this.authResponseKey);
    if (authResponseString) {
      try {
        return JSON.parse(authResponseString);
      } catch (e) {
        logging.error(`auth response of ${authResponseString} is not valid json`);
      }
    }
  }

  /**
   * save the full auth response
   */
  async setAuthResponse(response: any) {
    try {
      const authRespString = JSON.stringify(response);
      return localStorage.setItem(this.authResponseKey, authRespString);
    } catch (e) {}
  }

  async clear() {
    localStorage.removeItem(this.accessTokenKey);
    localStorage.removeItem(this.refreshTokenKey);
    localStorage.removeItem(this.idTokenKey);
    localStorage.removeItem(this.authResponseKey);
  }
}
