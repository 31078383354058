import { IIonicAuth, AuthResult, IonicAuthOptions, IHandlers } from './interfaces';
import { UrlInfo } from './UrlInfo';
import parse from 'url-parse';
import { UrlHelper } from './urlHelper';
import { IonicBaseAuth } from './base-auth';
import { logging } from './logging';

export class IonicAuthWeb<IDToken extends {} = any>
  extends IonicBaseAuth<IDToken>
  implements IIonicAuth<IDToken>
{
  protected logTag: string = 'IonicWebAuth: ';
  public lastAuthPopup: Window | null;

  constructor(public options: IonicAuthOptions, protected handlers: IHandlers) {
    super(options, handlers);
    this.lastAuthPopup = null;
    this.logger.debug(this.logTag, 'ctor options', options);
    window.addEventListener('message', event => {
      this.logger.debug(this.logTag, 'event: ', event);
    });
  }

  protected async internalGetToken(
    _codeName: string,
    _code: string,
    _grantType: string,
    _verifier: any,
    scope: string | undefined,
  ): Promise<AuthResult> {
    this.logger.debug(this.logTag, 'getting token');

    // setup the env for a new token request
    const keys = await this.authConfig.generateChallengeAndVerifier();
    await this.session.setAuthData(keys);
    const nonce = (await this.session.getNonce()) || '';

    // setup the url for the token
    let urlInfo: UrlInfo = await this.authConfig.getAuthorizeUrl(nonce, keys.challenge, {});
    let url = new URL(urlInfo.url as string);
    url.searchParams.set('prompt', 'none');
    url.searchParams.set('scope', scope as string);
    url.searchParams.set('response_type', 'token');

    this.logger.debug(this.logTag, 'url for internalGetToken: ', url.href);
    const result: any = await this.hiddenLoadUrl(url.href);

    const callbackString: string = result.callback;
    if (callbackString != undefined && callbackString != '') {
      const parsedUrl = parse(callbackString, true);

      let result: { [key: string]: any } = UrlHelper.parseHash(parsedUrl.hash);
      const authResult: AuthResult = {
        accessToken: result.access_token,
        idToken: result.id_token,
        refreshToken: result.refresh_token,
        expiresIn: result.expires_in,
        scope: result.scope,
        tokenType: result.token_type,
      };

      this.logger.debug(this.logTag, 'returning authResult: ', authResult);
      return authResult;
    } else {
      var error: string = 'could not get token';
      this.logger.error(error);
      throw error;
    }
  }

  async internalHandleCallback(url: string, externalCallback: boolean): Promise<AuthResult> {
    this.logger.debug(this.logTag, 'handleCallback url:' + url);

    const parsedUrl = parse(url, true);
    const searchParams = new URLSearchParams(parsedUrl.hash);
    this.logger.debug(this.logTag, 'searchParams: ', JSON.stringify(searchParams));
    if (searchParams.has('error_description')) {
      const errorDescription: string = searchParams.get('error_description') as string;
      this.logger.debug('error_description' + errorDescription);
      throw new Error(errorDescription);
    }

    const query_params = parsedUrl.query;
    this.logger.debug(this.logTag, 'query params: ', query_params);
    const hash = UrlHelper.parseHash(parsedUrl.hash);
    this.logger.debug(this.logTag, 'hash: ', hash);
    if (
      (hash.access_token != undefined && hash.id_token != undefined) ||
      (this.options.webAuthFlow &&
        this.options.webAuthFlow === 'PKCE' &&
        query_params.code != undefined)
    ) {
      let result: { [key: string]: any } = {};
      const session = await this.session.getAuthData();
      if (!session) {
        throw new Error('No session data stored');
      }

      this.logger.debug(this.logTag, 'got a session');

      if (query_params.code != undefined) {
        var options: { [key: string]: string } = {
          grant_type: 'authorization_code',
          client_id: this.options.clientID,
          code_verifier: session.verifier,
          code: query_params.code,
          redirect_uri: String(this.options.redirectUri),
        };
        result = await this.postToken(options);
      } else {
        result = hash;
      }
      return await this.handleAuthResult(result);
    } else {
      var error: string =
        'Web only supports implicit login with id and access token returned from the authorize call or PKCE';
      this.logger.error(error);
      throw error;
    }
  }

  async refreshSession(tokenName?: string): Promise<void> {
    this.logger.debug(this.logTag, 'refreshing session');
    await this.getOverrideDiscoveryUrl();

    if (tokenName) {
      this.logger.debug(this.logTag, 'refreshing other token: ', tokenName);
      const scope = await this.session.getTokenScopes(tokenName);
      const authResult = await this.internalGetToken('', '', '', undefined, scope);
      await this.setSession(authResult, tokenName, scope);
      return;
    }

    if (this.options.webAuthFlow === 'PKCE') {
      const refreshToken = await this.getRefreshToken();
      if (!refreshToken) {
        throw new Error('No refresh token available');
      }

      let options: { [key: string]: string } = {
        grant_type: 'refresh_token',
        client_id: this.options.clientID,
        refresh_token: refreshToken,
      };
      const result = await this.postToken(options);
      await this.handleAuthResult(result);
    } else {
      const keys = await this.authConfig.generateChallengeAndVerifier();
      await this.session.clearAuthData();
      await this.session.setAuthData(keys);
      this.logger.debug(this.logTag, 'keys: ', keys);

      const nonce = (await this.session.getNonce()) || '';
      this.logger.debug(this.logTag, 'nonce: ', nonce);

      let url: UrlInfo = await this.authConfig.getAuthorizeUrl(nonce, keys.challenge, {});
      url.url = url.url + '&prompt=none';
      this.logger.debug(this.logTag, 'url for refresh: ', url.url);
      try {
        const result: any = await this.hiddenLoadUrl(url.url);
        this.logger.debug(this.logTag, 'result for refresh: ', result);

        const callbackString: string = result.callback;
        if (callbackString != undefined && callbackString != '') {
          this.logger.debug(this.logTag, 'calling handleCallback');
          await this.internalHandleCallback(callbackString, false);
        }
      } catch (e) {
        var error: string = 'Failed to refresh session';
        this.logger.error(error);
        throw error;
      }
    }
  }

  private async handleAuthResult(result: { [key: string]: any }): Promise<AuthResult> {
    if (this.storage.setAuthResponse) {
      await this.storage.setAuthResponse(result);
    }
    this.logger.debug(this.logTag, 'result: ', result);
    const authResult = {
      accessToken: result.access_token,
      idToken: result.id_token,
      refreshToken: result.refresh_token,
      expiresIn: result.expires_in,
      scope: result.scope,
      tokenType: result.token_type,
    };
    await this.setSession(authResult);

    this.logger.debug(this.logTag, 'clear auth data');
    await this.session.clearAuthData();
    this.logger.debug(this.logTag, 'return auth result', authResult);
    return authResult;
  }

  private async postToken(options: { [key: string]: string }): Promise<{ [key: string]: any }> {
    const tokenUrlInfo: UrlInfo = await this.authConfig.getTokenUrl();
    const tokenUrl: string = tokenUrlInfo.url || '';
    const headers = {
      ...tokenUrlInfo.headers,
      'Content-Type': 'application/x-www-form-urlencoded',
    };
    options = { ...tokenUrlInfo.payload, ...options };

    const bodyParams = Object.keys(options)
      .map(key => {
        return encodeURIComponent(key) + '=' + encodeURIComponent(options[key]);
      })
      .join('&');

    const response = await fetch(tokenUrl, {
      method: 'POST',
      headers: headers,
      body: bodyParams,
    });
    if (!response.ok) {
      const jsonRes = await response.json();
      const errorMessage = `POST to token endpoint failed with error: ${
        jsonRes.error_description ? jsonRes.error_description : jsonRes.error
      }`;
      this.logger.error(errorMessage);
      throw errorMessage;
    }
    return JSON.parse(await response.text());
  }

  private hiddenLoadUrl(url: string): Promise<any> {
    try {
      return new Promise((resolve, reject) => {
        this.logger.debug(this.logTag, 'opening browser.');

        let iframeLocation;

        const iframe = document.createElement('iframe');
        iframe.style.display = 'none';
        iframe.src = url;
        document.getElementsByTagName('body')[0].appendChild(iframe);
        iframe.src = url;
        const that = this;
        var timer = window.setInterval(() => {
          try {
            if (iframe === null) {
              return;
            }
            if (iframe.contentWindow !== null) {
              iframeLocation = iframe.contentWindow.location;
            } else if (iframe.contentDocument !== null) {
              iframeLocation = iframe.contentDocument.location;
            } else {
              this.logger.debug(this.logTag, 'no doc or window');
              return;
            }

            if (
              !encodeURI(iframeLocation.href).indexOf(encodeURI(that.options.redirectUri as string))
            ) {
              window.clearInterval(timer);
              const href = iframeLocation.href;
              this.logger.debug(this.logTag, 'closing iframe: ', href);
              if (iframe.parentNode !== null) {
                iframe.parentNode.removeChild(iframe);
              }
              this.logger.debug(this.logTag, 'calling resolve');
              resolve({ event: 'opened', callback: href });
            }
            return;
          } catch (e) {
            window.clearInterval(timer);
            this.logger.error(this.logTag, e.message);
            reject(e.message);
          }
        }, 1);
      });
    } catch (err) {
      this.logger.error(this.logTag, 'hiddenLoadUrl error: ', err);
      throw err;
    }
  }

  protected showUrl(
    url: string,
    _options?: any,
    urlToCloseWindow: string = this.options.redirectUri!,
  ): Promise<{ event: string; callback: string } | void> {
    try {
      if (this.options.implicitLogin !== 'CURRENT') {
        // POPUP
        return new Promise((resolve, reject) => {
          this.logger.debug(this.logTag, 'opening browser.');
          let popupLocation;
          const popup = window.open(url, '_system');
          this.lastAuthPopup = popup;

          var timer = window.setInterval(() => {
            if (!popup || popup.closed) {
              window.clearInterval(timer);
              const error = 'popup window closed without navigating to result url';
              this.logger.error(this.logTag, error);
              reject(error);
            }

            try {
              if (!popup) {
                return;
              }
              popupLocation = popup.location;
              if (!encodeURI(popupLocation.href).indexOf(encodeURI(urlToCloseWindow))) {
                window.clearInterval(timer);
                const popupString = popupLocation.toString();
                this.logger.debug(this.logTag, 'closing popup: ', popupLocation);
                popup.close();
                this.logger.debug(this.logTag, 'closed popup', popupString);
                resolve({ event: 'opened', callback: popupString });
              }
              return;
            } catch (e) {
              // While the URL is at the auth provider, we will get a DOMException error trying to access the window.
              // We eat the error and try again.
            }
          }, 1);
        });
      } else {
        // CURRENT
        logging.debug(this.logTag, 'about to navigate forward');
        window.location.replace(url);
        return new Promise(() => {});
      }
    } catch (err) {
      this.logger.error(this.logTag, 'showUrl error: ', err);
      throw err;
    }
  }
}
