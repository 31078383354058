import { IonicAuthConfig, IonicAuthOptions, IonicGeneralAuthOptions } from '../interfaces';
import { UrlInfo } from '../UrlInfo';
import { OAuthPacker } from '../oauth-packer';
import { UrlHelper } from '../urlHelper';

export class IonicGeneralAuthConfig extends IonicAuthConfig {
  constructor(public options: IonicAuthOptions) {
    super(options);
    if (options.discoveryUrl == undefined) {
      throw "IonicAuthOptions.discoveryUrl must be defined, it usually is 'https://YOUR_DOMAIN/.well-known/openid-configuration'";
    }
  }

  async loadLocations(): Promise<void> {
    await super.loadLocations();
    if (this.locations['logout_endpoint'] == undefined) {
      const issuer: string = this.locations['issuer'];
      this.locations['logout_endpoint'] = issuer.endsWith('/')
        ? `${issuer}logout`
        : `${issuer}/logout`;
    }
  }

  async getAuthorizeUrl(
    nonce: string,
    challenge: string,
    parameters: { [id: string]: string },
  ): Promise<UrlInfo> {
    await this.loadLocations();
    const base = {
      ...parameters,
      clientId: this.options.clientID,
      redirectUri: this.options.redirectUri,
      audience: this.options.audience,
      nonce: nonce,
      state: nonce,
      scope: this.options.scope,
    };
    const PKCE = {
      code_challenge_method: 'S256',
      code_challenge: challenge,
      responseType: 'code',
    };
    if (this.options.platform !== 'web') {
      const options = { ...base, ...PKCE };
      return UrlHelper.buildUrl(
        this.locations['authorization_endpoint'],
        OAuthPacker.packParams(options),
      );
    } else {
      // web case
      const webBase = { ...base, client_secret: this.options.clientSecret };
      let options = {};
      if (this.options.webAuthFlow && this.options.webAuthFlow === 'PKCE') {
        options = { ...webBase, ...PKCE };
      } else {
        options = {
          ...webBase,
          response_type: 'id_token token',
          response_mode: 'fragment',
        };
      }
      return UrlHelper.buildUrl(
        this.locations['authorization_endpoint'],
        OAuthPacker.packParams(options),
      );
    }
  }

  async getLogoutUrl(): Promise<UrlInfo> {
    await this.loadLocations();
    const options = {
      clientId: this.options.clientID,
    };
    let params = OAuthPacker.packParams(options);
    params['returnTo'] = this.options.logoutUrl;
    let logoutUrl = UrlHelper.buildUrl(
      this.locations['end_session_endpoint'] || this.locations['logout_endpoint'],
      params,
    );
    return logoutUrl;
  }

  async getTokenUrl(): Promise<UrlInfo> {
    await this.loadLocations();
    const generalConfig: IonicGeneralAuthOptions = this.options;
    let payload: any = {};
    // defaults to true
    if (generalConfig.alwaysSendClientSecretOnLogin !== false && !!generalConfig.clientSecret) {
      payload = { client_secret: generalConfig.clientSecret };
    }

    return {
      url: this.locations['token_endpoint'],
      headers: {},
      payload: payload,
    };
  }
}
