export class messages {
  static ADDING_COOKIES_NOT_SUPPORTED: string =
    'auth-connect: string = "setHeader" does not support adding cookies, please use "setCookie" function instead';
  static DATA_TYPE_MISMATCH: string =
    'auth-connect: string = "data" argument supports only following data types:';
  static INVALID_CLIENT_AUTH_ALIAS: string =
    'auth-connect: string = invalid client certificate alias, needs to be a string or undefined';
  static INVALID_CLIENT_AUTH_MODE: string =
    'auth-connect: string = invalid client certificate authentication mode, supported modes are:';
  static INVALID_CLIENT_AUTH_OPTIONS: string =
    'auth-connect: string = invalid client certificate authentication options, needs to be an object';
  static INVALID_CLIENT_AUTH_PKCS_PASSWORD: string =
    'auth-connect: string = invalid PKCS12 container password, needs to be a string';
  static INVALID_CLIENT_AUTH_RAW_PKCS: string =
    'auth-connect: string = invalid PKCS12 container, needs to be an array buffer';
  static INVALID_DATA_SERIALIZER: string =
    'auth-connect: string = invalid serializer, supported serializers are:';
  static INVALID_FOLLOW_REDIRECT_VALUE: string =
    'auth-connect: string = invalid follow redirect value, needs to be a boolean value';
  static INVALID_HEADERS_VALUE: string = 'auth-connect: string = header values must be strings';
  static INVALID_HTTP_METHOD: string =
    'auth-connect: string = invalid HTTP method, supported methods are:';
  static INVALID_PARAMS_VALUE: string =
    'auth-connect: string = invalid params object, needs to be an object with strings';
  static INVALID_RESPONSE_TYPE: string =
    'auth-connect: string = invalid response type, supported types are:';
  static INVALID_SSL_CERT_MODE: string =
    'auth-connect: string = invalid SSL cert mode, supported modes are:';
  static INVALID_TIMEOUT_VALUE: string =
    'auth-connect: string = invalid timeout value, needs to be a positive numeric value';
  static MANDATORY_FAIL: string =
    'auth-connect: string = missing mandatory "onFail" callback function';
  static MANDATORY_SUCCESS: string =
    'auth-connect: string = missing mandatory "onSuccess" callback function';
  static LOGOUT_UNABLE_TO_RETRIEVE_TOKEN: string =
    'auth-connect: string = Unable to retrieve Id Token from storage';
}
