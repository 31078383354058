import { IonicGeneralAuthConfig } from './ionic-general-auth-config';
import { UrlHelper } from '../urlHelper';
import { OAuthPacker } from '../oauth-packer';
import { UrlInfo } from '../UrlInfo';

export class IonicKeyCloakConfig extends IonicGeneralAuthConfig {
  // The only unique salesforce quirk is that the challenge/verifier can only consist of hexidecimal chars

  async getLogoutUrl(): Promise<UrlInfo> {
    await this.loadLocations();

    const options = {
      clientId: this.options.clientID,
      postLogoutRedirectUri: this.options.logoutUrl,
    };

    return UrlHelper.buildUrl(
      this.locations['end_session_endpoint'],
      OAuthPacker.packParams(options),
    );
  }
}
