import { IonicAuthConfig, IonicAuthOptions } from '../interfaces';
import { UrlInfo } from '../UrlInfo';
import { OAuthPacker } from '../oauth-packer';
import { UrlHelper } from '../urlHelper';

export class IonicAzureConfig extends IonicAuthConfig {
  constructor(public options: IonicAuthOptions) {
    super(options);
    this.defaultDiscoveryUrl =
      'https://login.microsoftonline.com/common/v2.0/.well-known/openid-configuration';
  }

  async getAuthorizeUrl(
    nonce: string,
    challenge: string,
    parameters: { [id: string]: string },
  ): Promise<UrlInfo> {
    await this.loadLocations();
    const base = {
      ...parameters,
      clientId: this.options.clientID,
      redirectUri: this.options.redirectUri,
      audience: this.options.audience,
      nonce: nonce,
      state: nonce,
      scope: this.options.scope,
    };

    if (this.options.platform != 'web') {
      const options = {
        ...base,
        code_challenge_method: 'S256',
        code_challenge: challenge,
        responseType: 'code',
      };
      return UrlHelper.buildUrl(
        this.locations['authorization_endpoint'],
        OAuthPacker.packParams(options),
      );
    } else {
      // web case
      let options = {};
      if (this.options.webAuthFlow && this.options.webAuthFlow === 'PKCE') {
        options = {
          ...base,
          code_challenge_method: 'S256',
          code_challenge: challenge,
          responseType: 'code',
        };
      } else {
        options = {
          ...base,
          response_type: 'id_token token',
          response_mode: 'fragment',
          client_secret: this.options.clientSecret,
        };
      }
      return UrlHelper.buildUrl(
        this.locations['authorization_endpoint'],
        OAuthPacker.packParams(options),
      );
    }
  }

  async getLogoutUrl(): Promise<UrlInfo> {
    await this.loadLocations();

    const options = {
      clientId: this.options.clientID,
      returnTo: this.options.redirectUri,
      redirectUri: this.options.redirectUri,
      postLogoutRedirectUri: this.options.logoutUrl,
    };

    return UrlHelper.buildUrl(
      this.locations['end_session_endpoint'],
      OAuthPacker.packParams(options),
    );
  }

  async getTokenUrl(): Promise<UrlInfo> {
    await this.loadLocations();
    return {
      url: this.locations['token_endpoint'],
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        Accept: 'application/json',
      },
      payload: { scope: String(this.options.scope) },
    };
  }
}
