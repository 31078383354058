import { messages } from './messages';
import { jsUtil } from './js-util';

export class helpers {
  static validSerializers = ['urlencoded', 'json', 'utf8'];
  static validHttpMethods = ['get', 'put', 'post', 'patch', 'head', 'delete', 'upload', 'download'];
  static validResponseTypes = ['text', 'arraybuffer', 'blob'];

  static mergeHeaders(globalHeaders: { [id: string]: any }, localHeaders: { [id: string]: any }) {
    var globalKeys = Object.keys(globalHeaders);
    var key;

    for (var i = 0; i < globalKeys.length; i++) {
      key = globalKeys[i];

      if (!localHeaders.hasOwnProperty(key)) {
        localHeaders[key] = globalHeaders[key];
      }
    }

    return localHeaders;
  }

  static checkForValidStringValue(list: string[], value: any, onInvalidValueMessage: string) {
    if (jsUtil.getTypeOf(value) !== 'String') {
      throw new Error(onInvalidValueMessage + ' ' + list.join(', '));
    }

    value = value.trim().toLowerCase();

    if (list.indexOf(value) === -1) {
      throw new Error(onInvalidValueMessage + ' ' + list.join(', '));
    }

    return value;
  }

  static checkKeyValuePairObject(obj: any, allowedChildren: any, onInvalidValueMessage: string) {
    if (jsUtil.getTypeOf(obj) !== 'Object') {
      throw new Error(onInvalidValueMessage);
    }

    var keys = Object.keys(obj);

    for (var i = 0; i < keys.length; i++) {
      if (allowedChildren.indexOf(jsUtil.getTypeOf(obj[keys[i]])) === -1) {
        throw new Error(onInvalidValueMessage);
      }
    }

    return obj;
  }

  static checkHttpMethod(method: any) {
    return this.checkForValidStringValue(
      this.validHttpMethods,
      method,
      messages.INVALID_HTTP_METHOD,
    );
  }

  static checkResponseType(type: any) {
    return this.checkForValidStringValue(
      this.validResponseTypes,
      type,
      messages.INVALID_RESPONSE_TYPE,
    );
  }

  static checkSerializer(serializer: any) {
    return this.checkForValidStringValue(
      this.validSerializers,
      serializer,
      messages.INVALID_DATA_SERIALIZER,
    );
  }

  static checkForBlacklistedHeaderKey(key: string) {
    if (key.toLowerCase() === 'cookie') {
      throw new Error(messages.ADDING_COOKIES_NOT_SUPPORTED);
    }

    return key;
  }

  static checkForInvalidHeaderValue(value: any) {
    if (jsUtil.getTypeOf(value) !== 'String') {
      throw new Error(messages.INVALID_HEADERS_VALUE);
    }

    return value;
  }

  static checkTimeoutValue(timeout: any) {
    if (jsUtil.getTypeOf(timeout) !== 'Number' || timeout < 0) {
      throw new Error(messages.INVALID_TIMEOUT_VALUE);
    }

    return timeout;
  }

  static checkFollowRedirectValue(follow: any) {
    if (jsUtil.getTypeOf(follow) !== 'Boolean') {
      throw new Error(messages.INVALID_FOLLOW_REDIRECT_VALUE);
    }

    return follow;
  }

  static checkHeadersObject(headers: any) {
    return this.checkKeyValuePairObject(headers, ['String'], messages.INVALID_HEADERS_VALUE);
  }

  static checkParamsObject(params: any) {
    return this.checkKeyValuePairObject(params, ['String', 'Array'], messages.INVALID_PARAMS_VALUE);
  }

  static getMatchingHostHeaders(url: string, headersList: { [id: string]: any }) {
    var matches = url.match(/^https?\:\/\/([^\/?#]+)(?:[\/?#]|$)/i);
    var domain: any = matches && matches[1];

    return headersList[domain] || null;
  }

  static getMergedHeaders(
    url: string,
    requestHeaders: { [id: string]: any },
    predefinedHeaders: { [id: string]: any },
  ) {
    var globalHeaders = predefinedHeaders['*'] || {};
    var hostHeaders = this.getMatchingHostHeaders(url, predefinedHeaders) || {};
    var mergedHeaders = this.mergeHeaders(globalHeaders, hostHeaders);

    mergedHeaders = this.mergeHeaders(mergedHeaders, requestHeaders);

    return mergedHeaders;
  }

  static getAllowedDataTypes(dataSerializer: string) {
    switch (dataSerializer) {
      case 'utf8':
        return ['String'];
      case 'urlencoded':
        return ['Object'];
      default:
        return ['Array', 'Object'];
    }
  }

  static getProcessedData(data: any, dataSerializer: any) {
    var currentDataType = jsUtil.getTypeOf(data);
    var allowedDataTypes = this.getAllowedDataTypes(dataSerializer);

    if (allowedDataTypes.indexOf(currentDataType) === -1) {
      throw new Error(messages.DATA_TYPE_MISMATCH + ' ' + allowedDataTypes.join(', '));
    }

    if (dataSerializer === 'utf8') {
      data = { text: data };
    }

    return data;
  }

  static handleMissingCallbacks(successFn: any, failFn: any) {
    if (successFn === undefined) {
      throw new Error(messages.MANDATORY_SUCCESS);
    }

    if (failFn === undefined) {
      throw new Error(messages.MANDATORY_FAIL);
    }
  }

  static handleMissingOptions(options: { [id: string]: any }, globals: any) {
    options = options || {};

    return {
      method: this.checkHttpMethod(options.method || this.validHttpMethods[0]),
      responseType: this.checkResponseType(options.responseType || this.validResponseTypes[0]),
      serializer: this.checkSerializer(options.serializer || globals.serializer),
      timeout: this.checkTimeoutValue(options.timeout || globals.timeout),
      followRedirect: this.checkFollowRedirectValue(
        options.followRedirect || globals.followRedirect,
      ),
      headers: this.checkHeadersObject(options.headers || {}),
      params: this.checkParamsObject(options.params || {}),
      data: jsUtil.getTypeOf(options.data) === 'Undefined' ? null : options.data,
      filePath: options.filePath || '',
      name: options.name || '',
    };
  }
}
