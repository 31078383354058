import { IonicAuthOptions } from '../interfaces';
import { OAuthPacker } from '../oauth-packer';
import { UrlHelper } from '../urlHelper';
import { UrlInfo } from '../UrlInfo';
import { IonicGeneralAuthConfig } from './ionic-general-auth-config';
import { messages } from '../messages';

export class IonicOneLoginConfig extends IonicGeneralAuthConfig {
  constructor(
    options: IonicAuthOptions,
    private onGetRawIdToken: () => Promise<string | undefined>,
  ) {
    super(options);
  }

  async getLogoutUrl(): Promise<UrlInfo> {
    await this.loadLocations();

    let token: string | undefined;
    try {
      token = await this.onGetRawIdToken();
    } catch (e) {
      throw new Error(messages.LOGOUT_UNABLE_TO_RETRIEVE_TOKEN);
    }

    const options = {
      postLogoutRedirectUri: this.options.logoutUrl,
      idTokenHint: token,
    };

    return UrlHelper.buildUrl(
      this.locations['end_session_endpoint'],
      OAuthPacker.packParams(options),
    );
  }
}
