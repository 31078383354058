import { logging, Logger } from './logging';
import { UrlHelper } from './urlHelper';
import { UrlInfo } from './UrlInfo';
import { generateChallengeAndVerifier } from './crypto-helper';

/**
 * @hidden
 */
export interface IHandlers {
  onLoginSuccess(result: AuthResult): void;
  onLogout(): void;
}

/**
 * @hidden
 */
export interface IVConfig {
  isPasscodeSetupNeeded: boolean;
}

/**
 * @hidden
 */
export interface VaultInterface {
  getConfig(): Promise<IVConfig>;
  getValue(name: string): Promise<any>;
  storeValue(name: string, value: any): Promise<any>;
  clear(): Promise<void>;
}

/**
 * @hidden
 */
export interface IVUserInterface {
  getVault(): Promise<VaultInterface>;
  setPasscode(): Promise<void>;
}

export interface IV5UserInterface {
  getValue<T = any>(key: string): Promise<T | null>;
  setValue<T = any>(key: string, value: T): Promise<void>;
  clear(): Promise<void>;
  onLock(callback: () => void): void;
}

/**
 *
 * Provided by the hosting app, this interface allows the hosting app to configure,
 *   and provide information needed to login, logout.
 */
export interface IonicAuthOptions {
  /**
   * - Type: `string`
   *
   * Provided Application ID
   */
  clientID: string;

  /**
   * - Type: `string`
   *
   * Location that the hosting app expects logout callbacks to navigate to.
   */
  logoutUrl: string;

  /**
   * - Type: `string`
   *
   * Location that the hosting app expects callbacks to navigate to.
   */
  redirectUri?: string;

  /**
   * - Type: `string`
   *
   * User details requested from the Authentication provider, each provider may
   *   support standard {e.g. openid, profile, email, etc.}, or custom scopes.
   */
  scope?: string;

  /**
   * - Type: `string`
   *
   * Provided audience (aud) value
   */
  audience?: string;

  /**
   * - Type: `localStorage` | {@link TokenStorageProvider}
   *
   * The type of storage to use for the tokens
   */
  tokenStorageProvider?: 'localStorage' | TokenStorageProvider | IVUserInterface;

  /**
   * - Type: `string`
   *
   * Location of the Auth Server's discovery endpoint, can be null for Azure
   */
  discoveryUrl?: string;

  /**
   * - Type: `web` | `cordova` | `capacitor`
   *
   * Are we hosted in cordova, web, capacitor
   */
  platform?: 'web' | 'cordova' | 'capacitor';

  /**
   * - Type: `auth0` | `azure` | `cognito` | `identity-server` | `keycloak` | `okta` | `ping` | `salesforce` | `onelogin` | `general`
   *
   * The type of the Auth Server, currently only the following are supported:
   *
   *  * Auth0
   *  * Azure Active Directory: `azure
   *  * Cognito (AWS)
   *  * Identity Server
   *  * Keycloak
   *  * Okta
   *  * Ping
   *  * Salesforce
   *  * OneLogin
   *
   * 'general' is deprecated--please use a specific provider.
   */
  authConfig?:
    | 'auth0'
    | 'azure'
    | 'cognito'
    | 'salesforce'
    | 'okta'
    | 'ping'
    | 'identity-server'
    | 'keycloak'
    | 'onelogin'
    | 'general';

  /**
   * @hidden
   */
  clientSecret?: string;

  /**
   * - Type: `DEBUG` | `ERROR` | `NONE`
   *
   * The log level for the module
   */
  logLevel?: 'DEBUG' | 'ERROR' | 'NONE';

  /**
   * - Type: `private` | `shared` | `safari`
   *
   * * `private` - Avoids the prompt but the session will only be shared with Safari on iOS 10 or lower.
   * * `shared` - Allows for sharing a session between Safari and other applications
   * for a true SSO experience between apps but on iOS 11 and higher it will prompt the user for
   * permission to share the website data with the application.
   * * `safari` - Will start authentication flow externally in the Safari browser.
   */
  iosWebView?: 'private' | 'shared' | 'safari';

  /**
   * - Type: `string`
   *
   * setting to allow the toolbar color of the android webview control to be set. Takes a string that can be parsed as
   * a color by `android.graphics.Color.parseColor`
   */
  androidToolbarColor?: string;

  /**
   * - Type: `CURRENT` | `POPUP`
   *
   * determines the UI mode to use with web authentication in implicit. "CURRENT" will replace the current window with the authentication provider, and "POPUP" will open the authentication provider in a new window/tab.
   * When this is set to "CURRENT", you will need to use the {@link handleLoginCallback} and {@link handleLogoutCallback} to complete the auth
   */
  implicitLogin?: 'CURRENT' | 'POPUP';

  /**
   * - Type: `implicit` | `PKCE`
   *
   * Authentication flow to use on web
   * defaults to: `implicit`
   */
  webAuthFlow?: 'implicit' | 'PKCE';

  /**
   * - Type: {@link ISafariWebViewOptions}
   *
   * Additional configuration options to pass to the Safari Web View when iosWebView is set to "private".
   */
  safariWebViewOptions?: ISafariWebViewOptions;
}

/**
 * Configuration options to pass to the Safari Web View.
 */
export interface ISafariWebViewOptions {
  /**
   * - Type: `done` | `close` | `cancel`
   *
   * Configures the label of the dismiss button ("done", "close", or "cancel"). Defaults to "Done".
   */
  dismissButtonStyle?: 'done' | 'close' | 'cancel';
  /**
   * - Type: `string`
   *
   * The color to tint the background of the navigation bar and the toolbar. Must be in hex (#000000) format.
   */
  preferredBarTintColor?: string;
  /**
   * - Type: `string`
   *
   * The color to tint the control buttons on the navigation bar and the toolbar. Must be in hex (#000000) format.
   */
  preferredControlTintColor?: string;
}

/**
 * @hidden
 */
export interface IonicGeneralAuthOptions extends IonicAuthOptions {
  /**
   * should the 'client_secret' value always be passed in for login calls, regardless of implict(web) or not
   *  defaults to: true
   */
  alwaysSendClientSecretOnLogin?: boolean;
}

/**
 * @hidden
 */
export interface IIonicAuth<IDTokenType extends {} = any> {
  /**
   * Using configuration display the auth provider's login UI.
   *
   *  @param overrideUrl The overrideUrl parameter should only be used when the default
   *  discovery url needs to be overrode. (The known use case is with Azure AD
   *  custom user flows/policies.)
   */
  login(overrideUrl?: string): Promise<void>;

  additionalLoginParameters(parameters: { [id: string]: string }): void;

  getAccessToken(tokenName?: string, scopes?: string): Promise<string | undefined>;

  getRawIdToken(): Promise<string | undefined>;

  getIdToken(): Promise<IDTokenType | undefined>;

  getAuthResponse(): Promise<any | undefined>;

  isAccessTokenAvailable(tokenName?: string): Promise<boolean>;

  isAccessTokenExpired(): Promise<boolean>;

  isRefreshTokenAvailable(): Promise<boolean>;

  getRefreshToken(): Promise<string | undefined>;

  refreshSession(tokenName?: string): Promise<void>;

  isAuthenticated(): Promise<boolean>;

  logout(): Promise<void>;

  expire(): Promise<void>;

  handleLoginCallback(url?: string): Promise<AuthResult>;

  handleLogoutCallback(): Promise<void>;

  handleCallback(url: string): Promise<AuthResult>;

  onLoginSuccess(response: any): void;

  onLogout(): void;

  clearStorage(): Promise<void>;

  setOverrideDiscoveryUrl(url: string): Promise<void>;

  clearOverrideDiscoveryUrl(): Promise<void>;

  getOverrideDiscoveryUrl(): Promise<string | undefined>;

  getAccessTokenExpiration(): Promise<number | undefined>;
}

/**
 * This interface can be implemented by the hosting app, and set in the options
 *   it should be a wrapper around access to a secure storage solution if
 *   <a href="https://ionic.io/docs/identity-vault" target={null} rel={null}>Ionic Identity Vault</a> is not being used.
 */
export interface TokenStorageProvider {
  /**
   * Get the saved access token.
   *
   * @example
   * storage.getAccessToken()
   *
   * @param tokenName Optional token name, only used when multiple tokens are required (Azure specific feature).
   */
  getAccessToken?: (tokenName?: string) => Promise<string | undefined>;

  /**
   * Save the access token.
   *
   * @example
   * storage.saveAccessToken("123abcString", "mytoken")
   *
   * @param tokenName Optional token name, only used when multiple tokens are required (Azure specific feature).
   */
  setAccessToken?: (accessToken: string, tokenName?: string) => Promise<void>;

  /**
   * Get the saved refresh token.
   *
   * @example
   * storage.getRefreshToken()
   */
  getRefreshToken?: () => Promise<string | undefined>;

  /**
   * Save the refresh token.
   *
   * @example
   * storage.setRefreshToken("123abcString")
   */
  setRefreshToken?: (refreshToken: string) => Promise<void>;

  /**
   * Get the id token.
   *
   * @example
   * storage.getIdToken()
   */
  getIdToken?: () => Promise<string | undefined>;

  /**
   * Save the id token.
   *
   * @example
   * storage.setIdToken("123abcString")
   */
  setIdToken?: (idToken: string) => Promise<void>;

  /**
   * Get the full auth result.
   *
   * @example
   * storage.getAuthResponse()
   */
  getAuthResponse?: () => Promise<any>;

  /**
   * Save the full auth response.
   *
   * @example
   * storage.setAuthResponse("OK")
   */
  setAuthResponse?: (response: any) => Promise<void>;

  /**
   * Clear storage.
   *
   * @example
   * storage.clear()
   */
  clear?: () => Promise<void>;

  /**
   * Specify a callback to be called when the vault locks.
   *
   * @example
   * storage.onLock(() => {
   *   // do stuff
   * });
   */
  onLock?: (callback: () => void) => void;
}

////
// internal classes and data structures
////

/**
 * @hidden
 */
export interface AuthResult {
  expiresIn?: number;
  accessToken?: string;
  idToken?: string;
  refreshToken?: string;
  scope?: string;
  tokenType?: string;
}

const ready = new Promise<void>(resolve => {
  const DEVICE_READY_TIMEOUT = 5000;
  const readyTimeout = setTimeout(() => {
    console.warn(`Auth Connect: deviceready did not fire within ${DEVICE_READY_TIMEOUT}ms.`);
    resolve();
  }, DEVICE_READY_TIMEOUT);

  document.addEventListener('deviceready', () => {
    clearTimeout(readyTimeout);
    resolve();
  });
});

/**
 * @hidden
 */
export abstract class IonicAuthConfig {
  locations: any;
  defaultDiscoveryUrl: string;
  overrideDiscoveryUrl: string;
  currentDiscoveryUrl: string;
  logger: Logger;
  private logTag: string = 'IonicAuthConfig';

  constructor(public options: IonicAuthOptions) {
    this.defaultDiscoveryUrl = '';
    this.overrideDiscoveryUrl = '';
    this.currentDiscoveryUrl = '';
    this.locations = undefined;
    logging.setLogLevel(options.logLevel);
    this.logger = logging;
  }

  abstract getAuthorizeUrl(
    nonce: string,
    challenge: string,
    parameters: { [id: string]: string },
  ): Promise<UrlInfo>;
  abstract getLogoutUrl(): Promise<UrlInfo>;
  abstract getTokenUrl(): Promise<UrlInfo>;

  generateChallengeAndVerifier(): Promise<{
    verifier: string;
    challenge: string;
  }> {
    return generateChallengeAndVerifier();
  }

  private validateLocations(): boolean {
    if (this.locations === undefined) {
      this.logger.debug(this.logTag, 'locations undefined');
      return false;
    }
    // we have locations loaded are they the right ones? yep, unless we have an override
    if (this.overrideDiscoveryUrl === undefined || this.overrideDiscoveryUrl === '') {
      this.logger.debug(this.logTag, 'override discovery url empty or null');
      return true;
    }
    this.logger.debug(this.logTag, 'override discovery url: ', this.overrideDiscoveryUrl);

    // does the current equal the override?
    if (this.overrideDiscoveryUrl !== this.currentDiscoveryUrl) {
      this.logger.debug(this.logTag, 'override not eq current discovery url');
      return false;
    }

    this.logger.debug(this.logTag, 'all ok?');
    return true;
  }

  async loadLocations(): Promise<void> {
    if (this.validateLocations()) {
      return;
    }

    this.currentDiscoveryUrl = this.overrideDiscoveryUrl;
    if (this.currentDiscoveryUrl === '') {
      this.currentDiscoveryUrl = this.options.discoveryUrl || this.defaultDiscoveryUrl;
    }

    this.logger.debug(this.logTag, 'discoveryUrl: ', this.currentDiscoveryUrl);
    if (this.options.platform === 'cordova' || this.options.platform === 'capacitor') {
      // validate?
      await ready;
      try {
        const result = await UrlHelper.get(this.currentDiscoveryUrl);
        this.logger.debug(this.logTag, 'result.data: ', result);
        this.locations = JSON.parse(result.data);
      } catch (err) {
        throw new Error(err.error);
      }
    } else {
      const resp = await fetch(this.currentDiscoveryUrl);
      this.locations = await resp.json(); // Transform the data into json
      this.logger.debug(this.logTag, 'locations resp: ', this.locations);
    }
  }

  async getIssuer(): Promise<string> {
    await this.loadLocations();
    return this.locations['issuer'];
  }
}
