import { snakeCase } from 'lodash-es';
import { logging } from './logging';

var validAuthorizationParams = [
  'acr_values',
  'audience', // maybe auth0 specific
  'claims',
  'claims_locales',
  'client_id',
  'client_secret',
  'code_challenge',
  'code_challenge_method',
  'domain_hint',
  'display',
  'id_token_hint',
  'login_hint',
  'logout_uri',
  'max_age',
  'nonce',
  'post_logout_redirect_uri',
  'prompt',
  'redirect_uri',
  'registration',
  'request',
  'request_uri',
  'response_mode',
  'response_type',
  'return_to', // maybe auth0 specific
  'scope',
  'state',
  'ui_locales',
  'vtr',
];

type Params = { [index: string]: any };

export class OAuthPacker {
  static packParams(options: Params) {
    const validParams: Params = {};
    for (let key in options) {
      if (!options[key]) continue; // skip null/undefined/empty string values
      const snakedKey = snakeCase(key);
      if (validAuthorizationParams.includes(snakedKey)) {
        validParams[snakedKey] = options[key];
      } else {
        logging.debug('OAuthPacker::packParams', 'adding additional param:', key);
        validParams[key] = options[key];
      }
    }
    return validParams;
  }
}
