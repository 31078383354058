import { UrlInfo } from './UrlInfo';
import { logging } from './logging';
import { http } from './http';

export interface HTTPResponse {
  /**
   * The status number of the response
   */
  status: number;

  /**
   * The headers of the response
   */
  headers: any;

  /**
   * The URL of the response. This property will be the final URL obtained after any redirects.
   */
  url: string;

  /**
   * The data that is in the response. This property usually exists when a promise returned by a request method resolves.
   */
  data?: any;

  /**
   * Error response from the server. This property usually exists when a promise returned by a request method rejects.
   */
  error?: string;
}

export class UrlHelper {
  static logTag = 'UrlHelper: ';
  static buildUrl(url: string, options: { [index: string]: any }): UrlInfo {
    const params = new URLSearchParams(options);
    const urlObj = new URL(url);
    params.forEach((val, key) => urlObj.searchParams.append(key, val));
    logging.debug(urlObj.href, this.logTag, 'buildUrl: ');
    return new UrlInfo(urlObj.href);
  }

  static parseHash(urlHash: string): { [index: string]: string } {
    let params: { [index: string]: string } = {};
    let queries: string[];
    let temp: string[];
    let i: number;
    let l: number;
    if (urlHash[0] === '#') {
      urlHash = urlHash.slice(1);
    }

    // Split into key/value pairs
    queries = urlHash.split('&');

    // Convert the array of strings into an object
    for (i = 0, l = queries.length; i < l; i++) {
      temp = queries[i].split('=');
      params[temp[0]] = temp[1];
    }
    return params;
  }

  static post(url: string, data: any, headers: any): Promise<HTTPResponse> {
    logging.debug('UrlHelper::Post');
    return new Promise<HTTPResponse>((resolve, reject) => {
      return http.post(url, data, headers, resolve, reject);
    });
  }

  static get(url: string): Promise<HTTPResponse> {
    logging.debug('UrlHelper::Post');
    return new Promise<HTTPResponse>(function (resolve, reject) {
      return http.get(url, resolve, reject);
    });
  }
}
