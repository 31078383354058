/**
 * @hidden
 */
export interface StorageProvider {
  get(key: string): Promise<string | null>;
  set(key: string, value: string): Promise<void>;
  remove(key: string): Promise<void>;
  clear(): Promise<void>;
}

/**
 * @hidden
 */
export class WebStorageProvider implements StorageProvider {
  constructor(private keyPrefix = 'ionic-ac-storage:') {}

  async get(key: string): Promise<string | null> {
    return localStorage.getItem(this.getKey(key));
  }

  async set(key: string, value: string): Promise<void> {
    return localStorage.setItem(this.getKey(key), value);
  }

  async remove(key: string): Promise<void> {
    return localStorage.removeItem(this.getKey(key));
  }

  async clear(): Promise<void> {
    const length = localStorage.length;
    for (let i = 0; i < length; i++) {
      if (localStorage.key(i)?.startsWith(this.keyPrefix)) {
        localStorage.removeItem(localStorage.key(i)!);
      }
    }
  }

  private getKey(key: string) {
    return `${this.keyPrefix}${key}`;
  }
}

/**
 * @hidden
 */
export class NativeStorageProvider implements StorageProvider {
  async get(key: string): Promise<string | null> {
    return new Promise((res, rej) => {
      cordova.exec(
        function (result) {
          res(result);
        },
        function (error) {
          rej(error);
        },
        'IonicAuthConnectStorage',
        'get',
        [key],
      );
    });
  }

  async set(key: string, value: string): Promise<void> {
    return new Promise((res, rej) => {
      cordova.exec(
        function (_winParam) {
          res();
        },
        function (error) {
          rej(error);
        },
        'IonicAuthConnectStorage',
        'set',
        [key, value],
      );
    });
  }

  async remove(key: string): Promise<void> {
    return new Promise((res, rej) => {
      cordova.exec(
        function () {
          res();
        },
        function (error) {
          rej(error);
        },
        'IonicAuthConnectStorage',
        'remove',
        [key],
      );
    });
  }

  async clear(): Promise<void> {
    return new Promise((res, rej) => {
      cordova.exec(
        function () {
          res();
        },
        function (error) {
          rej(error);
        },
        'IonicAuthConnectStorage',
        'clear',
      );
    });
  }
}
