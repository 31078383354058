import { helpers } from './helpers';
import { globalConfigs } from './global-configs';

export class http {
  static sendRequest(url: string, options: any, success: any, failure: any) {
    helpers.handleMissingCallbacks(success, failure);

    options = helpers.handleMissingOptions(options, globalConfigs);

    var headers = helpers.getMergedHeaders(url, options.headers, globalConfigs.headers);

    switch (options.method) {
      case 'post':
      case 'put':
      case 'patch':
        var data = helpers.getProcessedData(options.data, options.serializer);
        return cordova.exec(success, failure, 'IonicHttp', options.method, [
          url,
          data,
          options.serializer,
          headers,
          options.timeout,
          options.followRedirect,
          options.responseType,
        ]);
      default:
        return cordova.exec(success, failure, 'IonicHttp', options.method, [
          url,
          headers,
          options.timeout,
          options.followRedirect,
          options.responseType,
        ]);
    }
  }

  static post(url: any, data: any, headers: any, success: any, failure: any) {
    return http.sendRequest(
      url,
      { method: 'post', data: data, headers: headers },
      success,
      failure,
    );
  }

  static get(url: any, success: any, failure: any) {
    return http.sendRequest(url, { method: 'get', params: {}, headers: {} }, success, failure);
  }

  static put(url: any, data: any, headers: any, success: any, failure: any) {
    return http.sendRequest(url, { method: 'put', data: data, headers: headers }, success, failure);
  }

  static patch(url: any, data: any, headers: any, success: any, failure: any) {
    return http.sendRequest(
      url,
      { method: 'patch', data: data, headers: headers },
      success,
      failure,
    );
  }

  static del(url: any, params: any, headers: any, success: any, failure: any) {
    return http.sendRequest(
      url,
      { method: 'delete', params: params, headers: headers },
      success,
      failure,
    );
  }

  static head(url: any, params: any, headers: any, success: any, failure: any) {
    return http.sendRequest(
      url,
      { method: 'head', params: params, headers: headers },
      success,
      failure,
    );
  }
}
