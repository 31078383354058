import { OAuthPacker } from '../oauth-packer';
import { UrlHelper } from '../urlHelper';
import { UrlInfo } from '../UrlInfo';
import { IonicGeneralAuthConfig } from './ionic-general-auth-config';

export class IonicPingAuthConfig extends IonicGeneralAuthConfig {
  async getLogoutUrl(): Promise<UrlInfo> {
    await this.loadLocations();

    const options = {
      clientId: this.options.clientID,
      redirectUri: this.options.redirectUri,
    };

    return UrlHelper.buildUrl(
      this.locations['ping_end_session_endpoint'],
      OAuthPacker.packParams(options),
    );
  }
}
