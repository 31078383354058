export class Logger {
  private log_debug = false;
  private log_error = true;

  setLogLevel(logLevel?: 'DEBUG' | 'ERROR' | 'NONE') {
    switch (logLevel) {
      case 'DEBUG':
        this.log_debug = true;
        this.log_error = true;
        break;
      case 'ERROR':
        this.log_debug = false;
        this.log_error = true;
        break;
      case 'NONE':
        this.log_debug = false;
        this.log_error = false;
        break;
      default:
        this.log_debug = false;
        this.log_error = true;
    }
  }

  debug(...args: any[]) {
    if (this.log_debug) {
      console.log(...args);
    }
  }

  error(...args: any[]) {
    if (this.log_error) {
      console.error(...args);
    }
  }
}

export const logging = new Logger();

declare global {
  interface Window {
    _ionicAuthLogging: any;
  }
}
window._ionicAuthLogging = logging;
