import { IonicAuthConfig, IonicAuthOptions } from '../interfaces';
import { UrlInfo } from '../UrlInfo';
import { OAuthPacker } from '../oauth-packer';
import { UrlHelper } from '../urlHelper';
import { base64Encode } from '../crypto-helper';

export class IonicCognitoConfig extends IonicAuthConfig {
  constructor(public options: IonicAuthOptions) {
    super(options);
    // validate that client_id and client_secret are set
    if (this.options.discoveryUrl == undefined) {
      throw "IonicAuthOptions.discoveryUrl must be defined for Cognito, it usually is 'https://cognito-idp.REGION.amazonaws.com/USER-POOL-ID'";
    }
  }

  async loadLocations(): Promise<void> {
    await super.loadLocations();
    if (this.locations['logout_endpoint'] == undefined) {
      const auth_endpoint: string = this.locations['authorization_endpoint'] || '';
      const url: URL = new URL(auth_endpoint);
      url.pathname = 'logout';
      this.locations['logout_endpoint'] = url.href;
    }
  }

  async getAuthorizeUrl(
    nonce: string,
    challenge: string,
    parameters: { [id: string]: string },
  ): Promise<UrlInfo> {
    await this.loadLocations();
    const base = {
      ...parameters,
      clientId: this.options.clientID,
      redirectUri: this.options.redirectUri,
      audience: this.options.audience,
      nonce: nonce,
      state: nonce,
      scope: this.options.scope,
    };
    const PKCE = {
      ...base,
      code_challenge_method: 'S256',
      code_challenge: challenge,
      responseType: 'code',
    };
    if (this.options.platform !== 'web') {
      const options = { ...PKCE };
      return UrlHelper.buildUrl(
        this.locations['authorization_endpoint'],
        OAuthPacker.packParams(options),
      );
    } else {
      // web case
      let options = {};
      if (this.options.webAuthFlow && this.options.webAuthFlow === 'PKCE') {
        options = { ...PKCE };
      } else {
        options = {
          ...base,
          response_type: 'token',
          response_mode: 'fragment',
        };
      }
      return UrlHelper.buildUrl(
        this.locations['authorization_endpoint'],
        OAuthPacker.packParams(options),
      );
    }
  }

  async getLogoutUrl(): Promise<UrlInfo> {
    await this.loadLocations();
    const options = {
      clientId: this.options.clientID,
      logoutUri: this.options.logoutUrl,
    };
    return UrlHelper.buildUrl(this.locations['logout_endpoint'], OAuthPacker.packParams(options));
  }

  async getTokenUrl(): Promise<UrlInfo> {
    await this.loadLocations();

    const headers: { [key: string]: any } = {};

    if (this.options.clientSecret) {
      const auth_header = base64Encode(
        new TextEncoder().encode(`${this.options.clientID}:${this.options.clientSecret}`),
      );
      headers['Authorization'] = `Basic ${auth_header}`;
    }

    return { url: this.locations['token_endpoint'], headers, payload: {} };
  }
}
