/**
 * @hidden
 */
export class UrlInfo {
  url?: string;
  headers?: {};
  payload?: {};
  constructor(url: string | any = undefined) {
    this.url = url;
    this.headers = undefined;
    this.payload = undefined;
  }
}
