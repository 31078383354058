export class SecureWebView {
  isAvailable(callback: (data: any) => any) {
    var errorHandler = function errorHandler(error: any) {
      // An error has occurred while trying to access the
      // SecureWebView native implementation, most likely because
      // we are on an unsupported platform.
      callback(false);
    };
    cordova.exec(callback, errorHandler, 'SecureWebView', 'isAvailable', []);
  }

  // options:
  //  url - url to display
  //  webView - for iOS which webview to display, if possible. By default we use the newest one available for OS version
  //      - ASWebAuth - ASWebAuthenticationSession (avaialble starting in iOS 12)
  //      - SFAuth - SFAuthenticationSession (available starting in iOS 11)
  //      - SFSafari - SFSafariViewController (available starting in iOS 9)
  //      - MobileSafari - Mobile Safari (pre-iOS 8)

  show(options: any | undefined, onSuccess: (data: any) => any, onError: (err: any) => any) {
    options = options || {};
    if (!options.hasOwnProperty('animated')) {
      options.animated = true;
    }
    cordova.exec(onSuccess, onError, 'SecureWebView', 'show', [options]);
  }

  hide(onSuccess: (data: any) => any, onError: (err: any) => any) {
    cordova.exec(onSuccess, onError, 'SecureWebView', 'hide', []);
  }

  getViewHandlerPackages(onSuccess: (data: any) => any, onError: (err: any) => any) {
    cordova.exec(onSuccess, onError, 'SecureWebView', 'getViewHandlerPackages', []);
  }

  useCustomTabsImplementation(
    packageName: string,
    onSuccess: (data: any) => any,
    onError: (err: any) => any,
  ) {
    cordova.exec(onSuccess, onError, 'SecureWebView', 'useCustomTabsImplementation', [packageName]);
  }

  connectToService(onSuccess: (data: any) => any, onError: (err: any) => any) {
    cordova.exec(onSuccess, onError, 'SecureWebView', 'connectToService', []);
  }

  warmUp(onSuccess: (data: any) => any, onError: (err: any) => any) {
    cordova.exec(onSuccess, onError, 'SecureWebView', 'warmUp', []);
  }

  mayLaunchUrl(url: string, onSuccess: (data: any) => any, onError: (err: any) => any) {
    cordova.exec(onSuccess, onError, 'SecureWebView', 'mayLaunchUrl', [url]);
  }
}

export const IonicSecureWebView = new SecureWebView();
