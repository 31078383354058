import { IonicAuthConfig, IonicAuthOptions } from '../interfaces';
import { UrlInfo } from '../UrlInfo';
import { OAuthPacker } from '../oauth-packer';
import { UrlHelper } from '../urlHelper';

export class IonicAuth0Config extends IonicAuthConfig {
  constructor(public options: IonicAuthOptions) {
    super(options);
    if (options.discoveryUrl == undefined) {
      throw "IonicAuthOptions.discoveryUrl must be defined for Auth0, it usually is 'https://YOUR_DOMAIN/.well-known/openid-configuration'";
    }
  }

  async loadLocations(): Promise<void> {
    await super.loadLocations();
    if (this.locations['logout_endpoint'] == undefined) {
      this.locations['logout_endpoint'] = this.locations['issuer'] + 'logout';
    }
  }

  async getAuthorizeUrl(
    nonce: string,
    challenge: string,
    parameters: { [id: string]: string },
  ): Promise<UrlInfo> {
    await this.loadLocations();
    const base = {
      ...parameters,
      clientId: this.options.clientID,
      redirectUri: this.options.redirectUri,
      audience: this.options.audience,
      nonce: nonce,
      state: nonce,
      scope: this.options.scope,
    };
    const PKCE = {
      code_challenge_method: 'S256',
      code_challenge: challenge,
      responseType: 'code',
    };
    if (this.options.platform !== 'web') {
      const options = { ...base, ...PKCE };
      return UrlHelper.buildUrl(
        this.locations['authorization_endpoint'],
        OAuthPacker.packParams(options),
      );
    } else {
      // web case
      const webBase = { ...base, client_secret: this.options.clientSecret };
      let options = {};
      if (this.options.webAuthFlow && this.options.webAuthFlow === 'PKCE') {
        options = { ...webBase, ...PKCE };
      } else {
        options = {
          ...webBase,
          response_type: 'id_token token',
          response_mode: 'fragment',
        };
      }
      return UrlHelper.buildUrl(
        this.locations['authorization_endpoint'],
        OAuthPacker.packParams(options),
      );
    }
  }

  async getLogoutUrl(): Promise<UrlInfo> {
    await this.loadLocations();
    const options = {
      clientId: this.options.clientID,
    };
    let params = OAuthPacker.packParams(options);
    params['returnTo'] = this.options.logoutUrl;
    let logoutUrl = UrlHelper.buildUrl(this.locations['logout_endpoint'], params);
    return logoutUrl;
  }

  async getTokenUrl(): Promise<UrlInfo> {
    await this.loadLocations();
    return { url: this.locations['token_endpoint'], headers: {}, payload: {} };
  }
}
